/* Hero styles */

$menuHeight: 0.1rem;

.hero {
	background-color: transparent;
	min-height: calc(#{$menuHeight} + 40rem); 

	&--slider {
		min-height: calc(#{$menuHeight} + 40rem);
		max-height: 100vh;
		overflow: hidden;
		opacity: 0;
		background-color: $blue;
		transition: opacity .5s ease-in-out;

		&.slick-initialized {
			opacity: 1;
		}


		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide > div {
			min-height: calc(#{$menuHeight} + 40rem);
		}

		.hero__slide {
			position: relative;
			min-height: calc(#{$menuHeight} + 40rem);
			display: flex !important;

			.container {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding-bottom: 12rem;
			}
		}

		.slick-dots {
			position: absolute;
			bottom: 5rem;
			left: 50%;
			transform: translate(-50%, 0);

			@extend .container;
		}

		.hero__content {
			padding-bottom: 0;
		}

		@include media-breakpoint-down(md) {
			min-height: calc(#{$menuHeight} + 40rem);

			.slick-list,
			.slick-track,
			.slick-slide,
			.slick-slide > div {
				min-height: calc(#{$menuHeight} + 40rem);
			}

			.hero__slide {
				min-height: calc(#{$menuHeight} + 40rem);
			}
		}

		@media (max-width: 1366px) and (max-height: 768px) {
			min-height: calc(#{$menuHeight} + 40rem);

			.slick-list,
			.slick-track,
			.slick-slide,
			.slick-slide > div {
				min-height: calc(#{$menuHeight} + 40rem);
			}

			.hero__slide {
				min-height: calc(#{$menuHeight} + 40rem);
			}
		}
	}

	&--large,
	&--video,
	&--default,
	&--product {
		position: relative;
		min-height: calc(#{$menuHeight} + 40rem);
		display: flex;
		align-items: flex-end;

		.image-overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}

		@include media-breakpoint-down(md) {
			min-height: 0;
			
			.hero__content {
				padding-top: 6rem;
				padding-bottom: 6rem;
			}
		}

		@media (max-width: 1366px) and (max-height: 768px) {
			min-height: 0;
			
			.hero__content {
				padding-top: 6rem;
				padding-bottom: 6rem;
			}

			.slick-list,
			.slick-track,
			.slick-slide,
			.slick-slide > div {
				min-height: calc(#{$menuHeight} + 44rem);
			}

			.hero__slide {
				min-height: calc(#{$menuHeight} + 44rem);
			}
		}
	}

	&--video {
		position: relative;
		width: 100%;
		overflow: hidden;
		// min-height: 0px !important;
		height: auto;
		align-items: stretch;

		&--news {
			align-items: flex-end;
			@include media-breakpoint-down(sm) {
				min-height: 0 !important;
			}
		}

		@include media-breakpoint-down(lg) {
			min-height: calc(#{$menuHeight} + 40rem);
		}

		.hero--slider {
			width: 100%;

			@media (max-width: 1366px) and (max-height: 768px) {
				min-height: calc(#{$menuHeight} + 30rem);
			}

			@include media-breakpoint-down(sm) {
				min-height: 0px;
			}

			.slick-list {
				height: 100%;
				min-height: 0px !important;
			}

			.slick-track {
				height: 100%;
				min-height: 0px !important;
			}

			.slick-slide {
				height: 100%;
				min-height: 0px !important;

				> div {
					height: 100%;
					min-height: 0px !important;
				}

			}

			.hero__slide {
				height: 100%;
				min-height: calc(#{$menuHeight} + 40rem);

				@include media-breakpoint-down(md) {
					min-height: calc(#{$menuHeight} + 30rem);
				}

				@include media-breakpoint-down(sm) {
					min-height: 0px;
				}

				.container {
					//display: none !important;

					@include media-breakpoint-down(md) {
						padding-bottom: 0;
					}

				}

			}

		}

		.hero__video {
			position: absolute;
			width: 100%;
			height: 0px;
			padding-bottom: 56.25%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			&.image-overlay:before {
				background-color: rgba(99,102,106,0.6);
			}

			iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			/*

			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			height: 0px;
			padding-bottom: 56.25%;

			iframe {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 100%;
				height: 100%;
				transform: translate(-50%, -50%);
			}

			@include media-breakpoint-down(md) {

				iframe {
					min-width: 768px;
				}
			}

			*/

		}

	}

	&--default {
		min-height: calc(#{$menuHeight} + 30rem);

		.hero__content {
			padding-bottom: 6.5rem;
		}

		h1,
		.alpha {
			margin-bottom: 0;
		}
	}

	&--no-hero,
	&--search {
		min-height: 0;
		padding-top: calc(#{$menuHeight} + 3rem);
		
		h1 {
			font-size: 3.2rem;
			color: $blue;
			margin-bottom: 0;
		}

		&.bg-blue {
			h1 {
				color: white;
			}
		}
	}

	&--search {
		padding: 7rem 0 4.6rem;
	}

	.container {
		position: relative;
		z-index: 10;
	}

	a {
		font-size: 1.5rem;
		font-weight: 400;
		color: inherit;
		display: inline-block;
		text-decoration: none;

		&:hover {
			color: #d6d6d6;
		}
	}

	&__content {
		padding-bottom: 8rem;
		> * + * {
			margin-top: 2.2rem;
			margin-bottom: 0;
		}

		p {
			@include fluid-type(1.8rem, 1.8rem);
			line-height: 1.476em;
		}
	}
}

html[data-useragent*="rv:11.0"] {
	.hero--slider .hero__slide .container {
		min-height: calc(.1rem + 50rem);
	}

	.hero--large .container .hero__content,
	.hero--product .container .hero__content {
		min-height: calc(.1rem + 60rem);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.hero--default .container .hero__content {
		min-height: calc(.1rem + 30rem);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}