//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

.text-light {
    color: white !important;

    h3, a {
		color: inherit;
	}
}

.text-dark {
    color: $font-clr !important;
}

.bg-light {
    background-color: #F2F2F2 !important;
}

.bg-blue {
	background-color: $blue !important;
	color: white;

	h3 {
		color: white;
	}
}

.bg-vitol-blue {
	background-color: $blue !important;
	color: white;

	h3 {
		color: white;
	}
}