.body--microsite {

	@include media-breakpoint-down(md) {
		padding-top: 7.5rem;
	}

	.bg-blue {
		background-color: var(--primary, #8A8D8F) !important;
	}

	.pb--reduce-padding {
		&--first {
			padding-top: 5.3rem !important;
		}
		&--last {
			padding-bottom: 5.3rem !important;
		}
	}

	&.home {
		.pb-stats__stat {
			h2 {
				font-size: 2.5rem;
			}
		}
	}

	.site-search {
		margin-left: 0;

		.search-toggle {
			margin-right: 0;
		}

		@include media-breakpoint-down(md) {
			margin-left: auto;
		}
	}

	.hero__content p {
		max-width: 50rem;
	}

	.pb-blocks .block-link .inner:hover.bg-blue {
		background-color: #6c6f71!important;
	}
	
	.pb-blocks__full-width {
		min-height: 32rem;

		> .block-image {
			&.col-md-8 {
				flex: 0 0 66.66667%;
    			max-width: 66.66667%;
			}

		}

		> .block-text {
			&.col-md-4 {
				flex: 0 0 33.33333%;
    			max-width: 33.33333%;
			}
		}

		> .block-text {
			&.col-md-12 {
				flex: 0 0 100%;
    			max-width: 100%;
			}
		}

		@include media-breakpoint-down(md) {
			> .block-image {
				&.col-md-8 {
					flex: 100%;
	    			max-width: 100%;
				}
			}

			> .block-text {
				&.col-md-4 {
					flex: 100%;
	    			max-width: 100%;
				}
			}
		}

		&--team {
			overflow: hidden;

			> .block-image {
    			position: relative;
    			overflow: hidden;
    			z-index: 1;

    			@include media-breakpoint-up(lg) {
	    			flex: 0 0 50%;
	    			max-width: 50%;
	    		}

	    		@include media-breakpoint-up(xl) {
	    			flex: 0 0 41.66667%;
	    			max-width: 41.66667%;
	    		}

	    		@include media-breakpoint-down(md) {
    				height: 800px;
    				min-height: 480px;
		    		order: 1;

		    		> .inner {
		    			max-height: none;
		    		}
		    	}

		    	@include media-breakpoint-down(sm) {
		    		height: 600px;
		    	}

		    	@include media-breakpoint-down(xs) {
		    		height: 480px;
		    	}

			}

			> .block-text {
				position: relative;
				z-index: 3;

				@include media-breakpoint-up(lg) {
	    			flex: 0 0 50%;
		    		max-width: 50%;
		    	}

		    	@include media-breakpoint-up(xl) {
	    			flex: 0 0 58.33333%;
		    		max-width: 58.33333%;
	    		}

		    	@include media-breakpoint-down(md) {
		    		background-color: #f2f2f2 !important;
		    		order: 2;

		    		&:after {
		    			top: 0 !important;
		    			bottom: auto !important;
		    			left: 0 !important;
		    			right: auto !important;
		    			width: 100px !important;
		    			height: 250px !important;
		    			border-width: 0 0 250px 100px !important;
				    	border-color: transparent transparent #fff transparent !important;
				    	z-index: 2 !important;

				    	@include media-breakpoint-down(sm) {
				    		display: none;
				    	}

		    		}

		    		&:before {
		    			display: none;
		    		}

		    	}

				&.block-link .inner {
					padding: 7rem 6rem 7rem 4rem;

					@include media-breakpoint-down(md) {
						padding: 5.5rem 2rem 5rem 11rem !important;
					}

					@include media-breakpoint-down(sm) {
						padding: 5rem 3rem !important;
					}

				}

				&.block-link :first-child.inner  {
					padding: 7rem 4rem 7rem 6rem;
				}

				.pre-heading {
    				margin-bottom: 1.5rem;
    			}

    			p {
    				margin-bottom: 0;
    			}

			}



		}

	}

	.pb-two-column__heading h3 {
		margin-bottom: .5em;
	}

	.pb-text-carousel {
		&--no-padding {
			padding-top: 0;
		}

		.inner {
			column-count: 2;
			column-gap: 2rem;

			@include media-breakpoint-down(md) {
				column-count: 1;
			}
		}

		&__optional-img {
			margin: 0 auto;
		}

		.slider-arrows {
			margin: 4.4rem 0 3rem -.5rem;
		}
	}

	.text-slider {
		&__slide {
			padding: 0 5rem;

			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}
	}

	.pb-block-links-product-slider--microsite {
		.product-slider {
			margin-top: 0;
		}
	}

	.pb-line-list--multisite {
		.pb-line-list__li {
			&.m-b-0 {
				margin-bottom: 0 !important;
			}
		}
	}

	.contact-map {
        position: relative;
        padding-bottom: 41%; // This is the aspect ratio
        height: 0;
        overflow: hidden;

        iframe {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100% !important;
	        height: 100% !important;
	    }

	    @include media-breakpoint-down(sm) {
	    	padding-bottom: 80%;
	    }
	}

    .accordion__header h4 {
        color: $font-clr;
    }

	#eu-cookie-bar .inner {
		background-color: $font-clr;
	}

	.m-t-20 {
		margin-top: 2rem;
	}
	.m-t-30 {
		margin-top: 3rem;
	}
	.m-t-40 {
		margin-top: 4rem;
	}
	.m-b-20 {
		margin-bottom: 2rem;
	}
	.m-b-30 {
		margin-bottom: 3rem;
	}
	.m-b-40 {
		margin-bottom: 4rem;
	}

	&.vg-mobility {
		$font-family: 'Outfit', sans-serif;

		font-family: $font-family;

		h1 {
			font-size: 6.3rem;
			line-height: 6.5rem;
			font-weight: 700;

			@include media-breakpoint-down(md) {
				font-size: 3.5rem;
				line-height: 3.5rem;
			}
		}

		h2 {
			font-size: 5rem;
			line-height: 4.8rem;
			margin-bottom: 3rem;

			@include media-breakpoint-down(md) {
				font-size: 2.5rem;
				line-height: 3rem;
				font-weight: 700;
			}
		}

		h3 {
			font-size: 3rem;
		}

		p {
		    font-size: 1.7rem;
		}

		.hero.hero--large.angled-container .hero__inner {
			h1 {
				color: #000000;
			    font-weight: 300;

				span {
				    font-weight: 700;
				    color: var(--primary, #8A8D8F) !important;
				}
			}
		}

		.hero--large.angled-container {
			padding-top: 250px;
			position:relative;
			min-height: calc(0.1rem + 40rem);
			display:flex;
			align-items:flex-end;
			overflow:hidden;

			@include media-breakpoint-down(sm) {
				padding-top: 200px;
			}

		}
		
		.hero--large.angled-container .hero__container {
			position: relative; 
			z-index: 10;
		}
		
		.hero--large.angled-container .hero__inner {
			position:relative;
			background:#F2F2F2;
			padding:4em 0;
			margin:0 0 6em 0;/*clip-path: polygon(0 0, 0% 100%, 44% 0);*/
		}
		
		.hero--large.angled-container .hero__inner:before {
			content:'';
			display:block;
			position:absolute;
			height:100%;
			top:0;
			background-color:#F2F2F2;
			left:-100%;
			width:100%;
		}
		
		.hero--large.angled-container .hero__inner:after {
			content:'';
			display:block;
			clip-path:polygon(0 0, 0% 100%, 100% 0);
			right:-100px;
			width:100px;
			height:100%;
			background:#F2F2F2;
			position:absolute;
			top:0;
		}
		
		.hero--large.angled-container .image-overlay {
			position:absolute;
			height:100%;
			width:100%;
			top:0;
			left:0;

			img {
			    object-position: right;
			}
		}

		.pb-angled-row-60-40 {
			position:relative;
			background-color:#F2F2F2;
			min-height:750px;
			display:flex;
			align-items:center;

			@include media-breakpoint-down(sm) {
				min-height: 0px;
			}

		}

		.pb-angled-row-60-40 .image__container {
			position:relative;
			z-index:10;
		}
		
		.pb-angled-row-60-40 .image__background {
			position:absolute;
			height:100%;
			width:60%;
			top:0;
			left:0;
			z-index:3;
		}
		
		.pb-angled-row-60-40.invert .image__background {
			position:absolute;
			height:100%;
			width:40%;
			top:0;
			left:0;
			z-index:3;

			@include media-breakpoint-down(xs) {
				// display: none;
			}
		}
		
		.pb-angled-row-60-40 .image__overlay {
			display:block;
			z-index:5;
			height:100%;
			width:460px;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			position:absolute;
			top:0;
			right:0;
		}

		.pb-blocks.add-bottom-margin {
		    padding-top: 7rem;
			margin-bottom: 29rem;

			.container {
			    margin-bottom: -25rem;
			}
		}

		.pb-blocks.remove-top-margin {
			@include media-breakpoint-down(sm) {
			    padding-top: 0;
			}
		}

		.pb-blocks__row.force-bg-light-blocks .block-text {
			@include media-breakpoint-down(sm) {
			    padding-right: 0px;
			    padding-left: 0px;
		        margin-bottom: 0;
		        min-height: inherit;
			}
		}

		.pb-blocks__row.force-bg-light-blocks .block-text .inner {
			background-color: #f0f0f0;
			color: var(--primary, #8A8D8F) !important;
		    padding-top: 5rem;
		    padding-bottom: 10rem;

			@include media-breakpoint-down(sm) {
			    padding-bottom: 3rem;
			}

			h3 {
				color: var(--primary, #8A8D8F) !important;
				font-weight: 700;

				@include media-breakpoint-down(md) {
					font-size: 2.2rem;
					line-height: 3rem;
				}
			}
		}

		.pb-blocks__row.force-bg-light-blocks .block-text:not(:last-child) .inner:after {
			@include media-breakpoint-down(sm) {
				content: '';
			    height: 1px;
			    width: 84%;
			    background-color: var(--primary, #8A8D8F) !important;
			    position: absolute;
			    display: block;
			    bottom: 0;
			}
		}

		.pb-angled-row-60-40 {
			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			.container > .row > div {
				@include media-breakpoint-down(sm) {
					padding-top: 10rem;
					padding-bottom: 8rem;
					margin-left: 0;
					width: 100%;
					flex: 0 0 100%;
    				max-width: 100%;
				}
			}

			.image__background {
				@include media-breakpoint-down(sm) {
					display: none;
					position: relative;
					width: auto;
				}
			}
		}

		.pb-angled-row-60-40.add_margin_bottom {
			margin-bottom: 7rem;

			@include media-breakpoint-down(sm) {
				overflow: hidden;
			}

			.image__container .row::before {
				@include media-breakpoint-down(sm) {
				    content: '';
				    width: 141px;
				    height: 375px;
				    position: absolute;
				    width: 0;
				    height: 0;
				    left: -50px;
				    border-style: solid;
				    border-width: 400px 150px 0 0;
				    border-color: #ffffff transparent transparent transparent;
				}

				@media (max-width: 767.98px) {
					display: none !important;
				}

			}

			.image__overlay {
				@include media-breakpoint-down(sm) {
					width: 67%;
				}
			}

			.image__image {
				@include media-breakpoint-down(sm) {
					max-height: 480px;
					width: auto;
				    object-fit: none;
				    object-position: 100% 50%;
				    float: right;
				}
			}

			.image__background.objectfit.bg-light.order-first {
				@include media-breakpoint-down(sm) {
					width: 100%;
				}

				.image__background__inner {
					height: 100%;

					@include media-breakpoint-down(sm) {
					    overflow: hidden;
					    width: 100%;
					}
				}

				.image__overlay {
					@include media-breakpoint-down(sm) {
						display: none;
					}
				}

				&::after {
					@include media-breakpoint-down(sm) {
					    content: '';
					    width: 141px;
					    height: 375px;
					    position: absolute;
					    border-style: solid;
					    border-width: 375px 141px 0 0;
					    border-color: #ffffff transparent transparent transparent;
					    right: -50px;
					    bottom: 0;
				        transform: scale(-1);
				   	}
			   	}
			}
		}

		.pb-services {
		    position: relative;
		    min-height: 750px;
		    height: 750px;
	        margin-bottom: 7rem;
	        display: flex;
	        width: 100%;
	        overflow: hidden;

	        @include media-breakpoint-down(md) {
	        	min-height: 0px;
	        	height: auto;
	        	background-color: #ffffff !important;
	        }

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			    height: 100%;
			}

		    .container {
				z-index: 4;
			    height: 100%;

			    .row {
			    	position: relative;
				    height: 100%;

				    &:after {
				    	content: '';
						position: absolute;
						top: 0;
						left: 50%;
						width: 1px;
						height: 108%;
						background-color: #8a8d8f;
						transform: translateX(-50%) rotate(21deg);
						margin-left: 204px;
						margin-top: -2%;
						opacity: 0;
						transition: opacity 0.35s ease;

						@include media-breakpoint-down(lg) {
					    	margin-left: 171px;
					    }

					    @include media-breakpoint-down(md) {
					    	display: none;
					    }

				    }

				    &.aos-animate {

				    	&:after {
				    		opacity: 1;
				    	}

				    	> div ul:before {
				    		opacity: 1;
				    	}
				    }

				    > div {
					    height: 100%;
					    padding-top: 10rem;

					    @include media-breakpoint-down(md) {
					    	padding-top: 0;
					    }

					    &::before {
						    content: '';
						    width: 282px;
						    height: 750px;
						    position: absolute;
						    border-style: solid;
						    border-width: 750px 282px 0 0;
						    border-color: #ffffff transparent transparent transparent;
						    left: -282px;
						    top: 0;
						    transform: scale(-1);

							@include media-breakpoint-down(md) {
								display: none;
							}
					    }

					    &::after {
							content: '';
						    width: 287px;
						    height: 762px;
						    position: absolute;
						    border-style: solid;
						    border-width: 762px 287px 0 0;
						    border-color: #fff transparent transparent transparent;
						    right: -287px;
						    top: 0;

							@include media-breakpoint-down(md) {
								display: none;
							}
					    }

					    h2 {
						    margin-left: 13%;
						    position: absolute;
						    width: 100%;
						    z-index: 15;
						    font-weight: 600;

							@include media-breakpoint-down(xs) {
							    position: relative;
							    margin: auto;
							    text-align: center;
							}
					    }

					    ul {
							margin-top: 5rem;
							position: absolute;
							width: 150%;
							z-index: 10;
							right: -20%;
							top: 20%;
							padding-right: 11rem;

							@include media-breakpoint-down(md) {
								&:before {
									content: '';
									position: absolute;
									top: -10%;
									right: 123px;
									width: 1px;
									height: 120%;
									background-color: #8A8D8F;
									transform: rotate(14.4deg);
									opacity: 0;
									transition: opacity 0.35s ease;
								}
							}

							@include media-breakpoint-down(sm) {
								&:before {
									content: '';
								    position: absolute;
								    top: -10%;
								    right: 88px;
								    width: 1px;
								    height: 120%;
								    background-color: #8a8d8f;
								    transform: rotate(20.5deg);
								    opacity: 0;
								    transition: opacity .35s ease;
								}
							}

							@media screen and (max-width: 600px) {
								&:before {
									right: 26.7%;
									transform: rotate(16.4deg);
								}
							}

							@include media-breakpoint-down(md) {
								position: relative;
								width: 100%;
							    margin-top: 7rem;
							    top: auto;
							    right: auto;
							    padding-left: 0;
							    padding-right: 20%;
							}

							@media screen and (max-width: 600px) {
								padding-right: 25%;
							}

						    li {
						    	list-style: none;
						    	font-size: 2.2rem;
						    	line-height: 2.8rem;
						    	color: var(--primary, #8A8D8F) !important;
						    	text-align: right;
						    	margin-bottom: 5rem;

						    	@media screen and (max-width: 600px) {
						    		width: 174px !important;
						    		height: 28px !important;
						    	}

						    	&:after {
						    		content: '';
						    		position: absolute;
						    		top: 16px;
						    		right: auto;
						    		bottom: auto;
						    		left: 100%;
						    		width: 60px;
						    		height: 1px;
						    		margin-left: 1rem;
						    		background-color: #8A8D8F;
						    		opacity: 0;
								    transition: opacity .5s ease;
								    transition-delay: 0.5s;

						    		@include media-breakpoint-down(sm) {
						    			top: 8px;
						    		}

						    		@media screen and (max-width: 600px) {
						    			width: 40px;
						    		}

						    	}

						    	&.aos-animate:after {
						    		opacity: 1;
						    	}
								
								@include media-breakpoint-down(md) {
								    float: right;
								    clear: both;
								}

								@include media-breakpoint-down(sm) {
									font-size: 1.2rem;
							    	line-height: 1.4rem;
							    	margin-bottom: 4rem;
							    	width: 60%;
								}

						    }

						    @for $i from 2 through 20 {
							  li:nth-child(#{$i}) {
							  	margin-right: #{ ($i - 1) * 3}rem;
								
								@include media-breakpoint-down(md) {
								  	margin-right: #{ ($i - 1) * 2}rem;
								}

							  }

							}

					    }
				    }
			    }
		    }

		    .image__background {
			    position: absolute;
			    height: 100%;
			    top: 0;
			    right: 0;
			    z-index: 3;

				@include media-breakpoint-down(xs) {
					position: relative;
					width: 100%;
				}

				&::after {

					@include media-breakpoint-down(xs) {
						content: '';
					    width: 141px;
					    height: 375px;
					    position: absolute;
					    border-style: solid;
					    border-width: 375px 141px 0 0;
					    border-color: #fff transparent transparent transparent;
					    left: 0;
					    top: 0;
					    background-color: transparent;
					    transform: none;
					}
				}

			    .image__background__inner {
					@include media-breakpoint-down(xs) {
					    overflow: hidden;
					    width: 100%;
				    }
			    }

			    .image__image {
					@include media-breakpoint-down(xs) {
					    max-height: 610px;
					    width: auto;
					    -o-object-fit: none;
					    object-fit: none;
					    -o-object-position: 100% 50%;
					    object-position: 100% 50%;
					    float: right;
					    position: relative;
				    }
			    }

			    img {
				    position: absolute;
				    right: 0;
			    }
		    }
		}

		.pb-blocks__full-width {
			&--team {
				> .block-image {
	    			&:after {
						content: '';
					    width: 338px;
					    height: 900px;
					    position: absolute;
					    border-style: solid;
					    border-width: 900px 338px 0 0;
					    border-color: #fff transparent transparent transparent;
					    right: -1px;
					    top: 0;
					    -webkit-transform: scale(-1);
					    transform: scale(-1);

					    @include media-breakpoint-down(md) {
					    	width: 100px;
					    	height: 250px;
					    	border-width: 250px 100px 0 0;
					    	top: auto;
					    	bottom: 0;
					    }

					    @include media-breakpoint-down(sm) {
	    					display: none;
	    				}

					}

					&:last-child {

						&:after {
							right: auto;
							left: -1px;
							top: auto;
							bottom: 0;
							border-width: 0px 0px 900px 338px;
		    				border-color: transparent transparent #fff transparent;

		    				@include media-breakpoint-down(md) {
		    					top: 0;
		    					bottom: auto;
		    					border-width: 0px 0px 250px 100px;
		    				}

		    				@include media-breakpoint-down(sm) {
		    					display: none;
		    				}
		    			}

					}
				}

				> .block-text {
					background: white;

					@include media-breakpoint-down(sm) {

					}

					&:after {
						content: '';
					    width: 338px;
					    height: 900px;
					    position: absolute;
					    border-style: solid;
					    border-width: 900px 338px 0 0;
					    border-color: #f2f2f2 transparent transparent transparent;
					    right: calc( 100% - 5rem );
					    top: 0;
					    -webkit-transform: scale(-1);
					    transform: scale(-1);
					    z-index: -1;
					}

					&:before {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: #f2f2f2;
						left: 5rem;
						top: 0;
						z-index: -1;
					}

					&:first-child {

						&:before {
							left: auto;
							right: 5rem;
						}

						&:after {
							right: auto;
							left: calc( 100% - 5rem );
							top: auto;
							bottom: 0;
							border-width: 0px 0px 900px 338px;
		    				border-color: transparent transparent #f2f2f2 transparent;
		    			}

					}
				}
			}
		}
	
	}

}