//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
	font-size: 62.5%;
    scroll-behavior: smooth;
}

* {
    scroll-margin: 13rem;
}

body {
	color: $font-clr;
    font-family: $font-family;
    font-weight: 400;
    padding-top: 12rem;

    @include media-breakpoint-down(md) {
        padding-top: 7rem;
    }
    @media (max-width: 1366px) and (max-height: 768px) {
        padding-top: 7rem;
    }
}

p {
	font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.447;
}

a {
    color: $blue;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.btn {
    display: inline-block;
    font-size: 1.5rem;
    color: $blue;

    &--back {
        position: relative;
        font-size: 1.7rem;
        
        &:before {
            content: "";
            height: 1.5rem;
            width: 1rem;
            background: url('../assets/images/arrow-blue.svg') no-repeat center center/contain;
            display: inline-block;
            position: relative;
            margin-right: 2rem;
            top: 1px;
            transform: rotate(-180deg);
        }
    }
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div,
a,
span {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
.compat-object-fit {
    background-size: cover;
    background-position: center center;
    img {
        opacity: 0;
    }
}

svg.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    //fill: currentColor;
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: relative;
        left: 0;
    }
}

.alignright {
    float: right;
    margin: 5px 0 15px 15px;
}
.alignleft {
    float: left;
    margin: 5px 15px 15px 0;
}
.aligncenter {
    clear: both; 
    display: block; 
    margin: 10px auto;
}
.alignnone {
    margin: 0px 10px;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}