//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

// SECTION
// -----------------------------------------------------------------------------

$tallBlockImageHeight: 12.5rem;
$squareMDHeight: 34.5rem;

.pb {
	padding: 3.3em 0;

	&--reduce-padding {
		padding: 0 0 2rem !important;
	}

	@include media-breakpoint-down(sm) {
		padding: 3.3em 0;
	}
}

.pb-block-links-product-slider {
	.product-slider {
		margin-top: 3.3em;

		.slick-track {
			display: flex !important;
		}

		.slick-slide {
			height: auto;
		}

		.slick-slide > div {
		    flex-basis: 100%;
		    display: flex !important;
		}

		&.slick-initialized .slick-slide {
		    display: flex !important;
		}

		&__link span {
			text-transform: lowercase;
		}

		&__slide {
			display: flex !important;
			width: 100%;
			max-width: 100%;
    		flex-basis: 100%;

			> div {
				min-height: 100%;
				width: 100%;
    			flex-basis: 100%;
			}

			@include media-breakpoint-down(md) {
				height: auto;

				> div {
					display: block;
				}

				.product-slider__image-block,
				.image-cover {
					height: 24rem;
				}

				.block {
					min-height: 100%;
				}
			}
		}

		.inner {
			padding: 3rem;

			h3 {
				margin-bottom: 2rem;
				font-size: 2.5rem;
			}

			p {
				margin-bottom: 3.4rem;
				font-size: 1.5rem;
			}
		}

		@include media-breakpoint-down(md) {
			.product-slider__image-block {
				min-height: 0;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-top: 5rem;
		}
	}
}

.pb-heading-text {
	padding: 5.3em 0;

	&__content {
		padding: 0 6%;

		> * + * {
			margin-top: 3rem;
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: 0;

			+ p {
			margin-top: 2rem;
			}
		}
	}
}

.pb-video-modal {
	position: relative;
	height: 70rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		position: absolute;
	}

	&__btn {
		position: relative;
		z-index: 2;
		appearance: none;
		cursor: pointer;
		background-color: transparent;
		border: none;
		font-size: 2.7rem;
		color: white;

		&:focus {
			outline: none;
		}

		img {
			margin: 1.7rem auto 0;
			height: 1.7rem;
			position: relative;
			display: block;
		}

		&:hover {
			opacity: .7;
		}
	}

	&__modal {
		position: fixed;
		z-index: 900;
		height: 100vh;
		width: 100vw;
		background-color: rgba(black, .7);
		top: 0;
		left: 0;
		padding: 10vh 5vw;
		display: none;

		&.open {
			display: block;
		}

		.inner {
			position: relative;
		    padding-bottom: 56.25%;
		    height: 0;
		    overflow: hidden;
		    iframe {
		        position: absolute;
		        top:0;
		        left: 50%;
    			transform: translate(-50%, 0);
		        width: 100%;
		        height: 100%;
		        max-height: 80vh;
    			max-width: 1506px;
		    }
		}
	}

	&__close {
		position: absolute;
	    top: 1rem;
    	right: 3.5rem;
	    appearance: none;
	    border: none;
	    background-color: transparent;
    	color: #fff;
	    font-size: 4.8rem;
	    padding: .15rem;
	    line-height: 1;
	    transform: rotate(45deg);
	    cursor: pointer;
	}

	@include media-breakpoint-down(sm) {
		height: 50rem;
	}
}

.pb-two-column {
	&__col {
		padding-left: 8.33333%;
		padding-right: 8.33333%;

		&:nth-of-type(1) {
			border-right: 1px solid rgba(#8A8D8F, .25);
		}

		@include media-breakpoint-down(sm) {
			padding-left: 1.5rem;
			padding-right: 1.5rem;

			&:nth-of-type(1) {
				border-right: none;
				padding-bottom: 5rem;
			}
		}
	}

	&__heading {
		margin-bottom: 3.5rem;

		h3 {
			margin-bottom: 0;
		}
	}

	a {
		@extend .btn;

		&:hover {
			color: $font-clr;
		}
	}
}

.block-link {
	a {
		color: $font-clr;
		&:hover {
			text-decoration: none;
		}

		p {
			&:last-child:after {
				content: "";
				height: 0.8em;
				width: 0.8em;
				background: url('../assets/images/arrow-blue.svg') no-repeat center center / contain;
				display: inline-block;
				position: relative;
				margin-left: 1rem;
				top: 1px;
			}
		}

		&.bg-blue,
		&.bg-vitol-blue,
		&.bg-primary {
			p:last-child:after {
				content: "";
				height: 0.8em;
				width: 0.8em;
				background: url('../assets/images/arrow-white.svg') no-repeat center center / contain;
				display: inline-block;
				position: relative;
				margin-left: 1rem;
				top: 1px;
			}
		}
	}


	.bg-blue,
	.bg-vitol-blue,
	.text-light {
		color: white;

		a {
			color: inherit;

			p:last-child:after {
				content: "";
				height: 0.8em;
				width: 0.8em;
				background: url('../assets/images/arrow-white.svg') no-repeat center center / contain;
				display: inline-block;
				position: relative;
				margin-left: 1rem;
				top: 1px;
			}
		}
	}

	&.with-image {
		.inner {
			> * {
				color: white;
			}

		}

		.bg-image {
			&.text-dark .inner {
				> * {
					color: $font-clr;
				}
			}
		}
	}

	.bg-image {
		&.text-light {
			@extend .image-overlay;
		}

		&.text-dark {
			@extend .image-overlay;

			&:before {
		        background-color: rgba(255, 255, 255, 0.65);
		        
		        @supports(mix-blend-mode: multiply) {
		            mix-blend-mode: revert;
		        }
		    }
		}

		.inner {
			position: relative;
			z-index: 10;
		}
	}

	a[href^="javascript:void(0);"] {
		cursor: auto;
		p:after {
			display: none;
		}
	}
}

.pb-blocks {

	h3 {
		font-size: 1.7rem;
		line-height: 1.4;
	}

	p {
		font-size: 1.5rem;
	}

	.inner,
	a.inner {
		display: block;
		z-index: 6;
	}

	&__row {
		> .block-text {
			min-height: 273px;

			&.with-image {
				.row {
					height: 100%;
				}

				a.inner,
				span.inner {
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
		}
	}

	&__row:not(:last-of-type) {
		margin-bottom: 3rem;
	}

	@include media-breakpoint-down(md) {
		&__row {
			> div:not(:last-of-type) {
				margin-bottom: 3rem;
			}
		}
	}

	&.bg-white {
		.bg-white {
			@extend .bg-light;
		}
	}

	&__header {
		//margin-bottom: 8.3rem;
		margin-bottom: 3.3rem;

		h3 {
			font-size: 1.8rem;
		}
	}

	.block-link {
		.inner {
			//padding: 5rem;
			padding: 3rem;
			min-height: 100%;

			@include media-breakpoint-down(sm) {
				padding: 3rem;
			}
		}

		span.inner {
			a {
				text-decoration: underline;
			}
		}

		a.inner {
			transition: all .1s ease-in-out;

			&:hover {
				&.bg-white  {
					background-color: rgba(#63666A, 0.15) !important;
				}

				&.bg-blue  {
					background-color: darken($blue, 7%) !important;
				}

				&.bg-vitol-blue  {
					background-color: darken($blue, 7%) !important;
				}
			}
		}
	}

	.block-image {
		position: relative;

		img {
			transition: all .1s ease-in-out;
		}

		.inner {
			overflow: hidden;

			@include media-breakpoint-down(md) {
				max-height: 43rem;
			}
		}

		&--caption {
			.inner {
				position: relative;
			}
		}

		.wp-caption-text {
			position: absolute;
			bottom: 1.3rem;
			left: 2.2rem;
			font-size: 1.2rem;
			text-transform: uppercase;
			line-height: 1;
			color: white;
		}

		@include media-breakpoint-down(md) {
			height: $squareMDHeight;
		}
	}

	&--four-square {
		display: grid;
	    grid-template-columns: repeat(2, 1fr);
	    grid-auto-rows: 1fr;
	    grid-row-gap: 3rem;

		> div {
			margin-bottom: 3rem;
			@supports(display: grid){
				max-width: 100%;
				flex-basis: 100%;
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-down(sm) {
			display: block;

			> div:not(:last-of-type) {
				margin-bottom: 3rem;
			}
		}

		> .block-image {
			height: 39.5rem;
			.col {
				height: 39.5rem;
			}
		}

		> .block-text {
			height: 39.5rem;
			.col {
				height: 39.5rem;
			}

			&.with-image {
				.inner {
					position: absolute;
					top: 0;
					width: 100%;
				}

				.row {
					min-height: 100%;
				}
			}
		}

		@include media-breakpoint-down(lg) {
			> .block-image,
			> .block-text {
				height: 33.5rem;
				.col {
					height: 33.5rem;
				}
			}
		}

		@include media-breakpoint-down(md) {
			> .block-image {
				height: auto;
				.col {
					height: auto;
				}
			}

			> .block-text {
				height: auto;
				.col {
					height: auto;
				}
			}
		}
	}

	&__full-width {
		> .block-image {
			max-width: calc(33.33333% + 9px);
			flex-basis: calc(33.33333% + 9px);
		}

		> .block-text {
			max-width: calc(66.66667% - 9px);
			flex-basis: calc(66.66667% - 9px);
		}

		@include media-breakpoint-down(md) {
			> .block-image {
				max-width: 100%;
				flex-basis: 100%;
			}

			> .block-text {
				max-width: 100%;
				flex-basis: 100%;
			}
		}

		> div:nth-of-type(1) {
			margin-bottom: 0;
		}

		&:hover {
			.block-image a.inner {
				cursor: pointer;

				img {
					filter: contrast(0.6) !important;
				}
			}

			.block-link {
				a.inner.bg-white {
					background-color: rgba(99,102,106,.15)!important;
				}

				span.inner.bg-white {
					background-color: #fff !important;
				}
			}

			.block-image .inner[href^="javascript:void(0);"] {
				cursor: auto;

				img {
					filter: contrast(1) !important;
				}
			}
		}
	}
}

.block-link,
.pb-blocks .block-link {
	[href^="javascript:void(0);"] {
		cursor: auto;

		&:hover {
			&.bg-white  {
				background-color: white !important;
			}

			&.bg-blue  {
				background-color: $blue !important;
			}

			img {
				filter: contrast(1) !important;
			}
		}
	}
}

.block {
	&.block-link {
		display: flex;
		a {
			display: block;
			transition: all .1s ease-in-out;

			img {
				transition: inherit;
			}
		}
		&--tall {
			.block__image,
			.objectfit {
				height: $tallBlockImageHeight * 1.1;
			}
			.inner {
				min-height: calc(#{$tallBlockImageHeight} * 2.2);
				padding: 3rem;

				h3 {
					font-size: 1.7rem;
				}

				p {
					font-size: 1.5rem;
				}
			}

			a:hover {
				background-color: rgba(#63666A, 0.15) !important;

				img {
					filter: contrast(0.6);
				}
			}

			@include media-breakpoint-down(md) {
				.inner {
					min-height: auto;
				}
			}

			@include media-breakpoint-down(sm) {
				padding-bottom: 3.3em;

				&:last-child {
					padding-bottom: 0;
				}

				.objectfit {
					//display: none; /* For if we hide images on mobile */
				}
			}
		}
	}

	.inner {
		padding: 5rem;

		@include media-breakpoint-down(sm) {
			padding: 3rem;
		}
	}
}

.block-large-image {
	//min-height: 79rem;

	span.inner,
	a.inner {
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 100%;

		&.image-overlay:before {
			top: 0;
			left: 0;
			z-index: 1;
			transition: all .1s ease-in-out;
		}

		> div, h3, p {
			z-index: 2;
			position: relative;
			max-width: 33rem;
		}
	}

	a.inner {
		&:hover {
			&.image-overlay:before {
				background-color: rgba(99,102,106,.85);
			}
		}
	}

	@include media-breakpoint-down(md) {
		min-height: 34.5rem;
	}
}

.block-text-image {
	&--in-large {
		.block-image,
		.block-text {
			flex-basis: 100%;
			max-width: 100%;
			height: 43rem;
		}
	}

	> .row {
		min-height: 100%;
	}

	&__image {
		img {
			transition: all .1s ease-in-out;
		}

		@media(min-width: 769px) {
			max-width: calc(50% + 15px);
			flex-basis: calc(50% + 15px);
		}
	}
	&__text {
		@media(min-width: 769px) {
			max-width: calc(50% - 15px);
			flex-basis: calc(50% - 15px);
		}
	}

	&:hover {
		.block-text-image__image a {
			img {
				filter: contrast(0.6);
			}

			[href^="javascript:void(0);"] {
				img {
					filter: contrast(1);
					cursor: auto;
				}
			}
		}

		.block-text-image__text {
			&.block-link {
				transition: all .1s ease-in-out;
				
				a.bg-white  {
					background-color: rgba(#63666A, 0.15) !important;
				}

				a.bg-blue  {
					background-color: darken($blue, 7%) !important;
				}

			}
		}
	}

	.flex-column {
		.block-text-image__image,
		.block-text-image__text {
			height: 39.5rem;
			max-width: 100%;
			flex-basis: 100%;

			@include media-breakpoint-down(md) {
				height: auto;
			}
		}

		> .block-image,
		> .block-text {
			margin-bottom: 3rem;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&--reset {
			height: 100%;
		}

		@supports (-moz-appearance:none) {
			flex-direction: unset !important;
		}
	}

	&--span-two,
	&--in-large {
		.bg-image {
			&.text-light {
				@extend .image-overlay;

				a > * {
					color: white;
				}

				p:last-child:after {
					content: "";
					height: 0.8em;
					width: 0.8em;
					background: url('../assets/images/arrow-white.svg') no-repeat center center / contain;
					display: inline-block;
					position: relative;
					margin-left: 1rem;
					top: 1px;
				}
			}

			&.text-dark {
				@extend .image-overlay;

				&:before {
			        background-color: rgba(255, 255, 255, 0.65);
			        
			        @supports(mix-blend-mode: multiply) {
			            mix-blend-mode: revert;
			        }
			    }
			}

			.objectfit {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

			.inner {
				position: relative;
				z-index: 10;
			}
		}
	}	

	@include media-breakpoint-down(lg) {
		&--in-large {
			.block-image,
			.block-text {
				height: 33.5rem;
			}
		}

		.flex-column {
			.block-text-image__image,
			.block-text-image__text {
				height: 33.5rem;
			}
		}
	}

	&--span-two {
		.block-text-image--span-two--has-image-bg {
			.block-text-image__image.bg-image {
				position: absolute;
			    left: 15px;
			    width: calc(100% - 30px);
			}
		}

		.block-text-image__text.bg-image,
		.block-text-image__image.bg-image {
			@include media-breakpoint-down(lg) {
				height: 33.5rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		&__image {
			min-height: $squareMDHeight;
		}

		&--in-large {
			.block-text {
				height: auto;
			}
		}

		.flex-column {
			.block-text-image__image,
			.block-text-image__text {
				height: auto;
				min-height: 0;
			}

			.block-text-image__text.bg-image,
			.block-text-image__image.bg-image {
				height: 100%;
			}
		}

		&--span-two {
			.flex-column {
				flex-direction: row !important;
			}

			.col-md-12 {
				max-width: 50% !important;
				flex-basis: 50% !important;
			}

			.flex-column--reset {
				.col-md-12 {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&--span-two {
			.flex-column {
				flex-direction: row !important;
			}

			.col-md-12 {
				max-width: 100% !important;
				flex-basis: 100% !important;
			}
		}

		&__image {
			max-height: 34.5rem;
		}
	}
}

html[data-useragent*="Edge/18"] {
	.block-text-image__image {
		max-width: 50%;
		flex-basis: 50%;
	}

	.block-text-image .flex-column {
		flex-direction: unset !important;
	}
}
html[data-useragent*="rv:11.0"] {
    .block-text-image {
    	&__image {
			.compat-object-fit {
				min-height: 430px;
			}
    	}

    	.flex-column {
			flex-direction: inherit !important;
		}
    }
}

.pb-full-width-banner {
	position: relative;
	padding: 25rem 0;

	&.image-overlay:before {
		top: 0;
	}

	.image-cover {
		position: absolute;
	}

	.container {
		position: relative;
		z-index: 10;
	}

	.btn {
		font-size: 1.7rem;
	}

	h3 {
		margin-bottom: 3rem;
	}

	@include media-breakpoint-down(sm) {
		padding: 15rem 0;
	}
}

.pb-full-width-announcement {
	position: relative;
	padding: 25rem 0;
	overflow: hidden;

	@media screen and (max-width: 1540px) {
		padding: 20rem 0;
	}

	@media screen and (max-width: 1080px) {
		padding: 12rem 0;
	}

	@media screen and (max-width: 768px) {
		padding: 0;
		// margin-bottom: 8rem;

		&.pb-full-width-banner .image-cover {
			position: relative;
			min-height: 250px;
		}

	}

	&:before {
		display: none;
	}

	&__video {
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;

		iframe {
			position: absolute;
			left: 50% !important; 
			top: 50% !important;
			width: 100%; 
			height: auto !important;
			min-height: 100%;
			min-width: 100vw;
			transform: translate(-50%, -50%);
			aspect-ratio: 16 / 9;
		}

		@media screen and (max-width: 768px) {
			position: relative;
			height: auto;

			iframe {
				position: relative;
				left: 0 !important;
				top: 0 !important;
				transform: none;
			}

		}

	}

	.image-overlay {

		&:before {
			display: none;
		}

		img {

			@media screen and (max-width: 768px) {
				min-height: 250px;
			}

		}

	}

	&__content {
		position: relative;
		display: table;
		width: auto;
		min-width: 420px;
		max-width: 50%;
		background-color: #002b54;
		color: #ffffff;
		margin: 0 auto 0 0;
		padding: 3rem;
		text-align: left;

		&:before {
			content: '';
			position: absolute;
			left: -990px;
			top: 0;
			width: 999px;
			height: 100%;
			background-color: #002b54;
		}

		&.text-dark {
			background-color: #ffffff;
			color: #63666a;

			&:before {
				background-color: #ffffff;
			}

		}

		&.position-right {
			margin: 0 0 0 auto;

			&:before {
				left: auto;
				right: -990px;
			}
		}

		.btn {
			margin-top: 3rem;
		}

		@media screen and (max-width: 768px) {
			display: block;
			width: 100%;
			height: auto;
			margin: 0;
			max-width: 100%;
			min-width: 100%;

			&:before {
				left: -250px !important;
				right: auto !important;
				z-index: -1;
			}
		}


	}


}

.pb-global-map {
	.container {
    	max-width: 1420px;
	}

	&__map {
		overflow: hidden;

		area {
			cursor: pointer;
		}
	}

	&__key {
		height: 0.8rem;
		width: 0.8rem;
		margin-right: 1rem;
	}

	&__item {
		display: none;

		&--show {
			display: block;
		}

		p {
			font-size: 1.5rem;
		}
	}

	&__heading {
		margin-bottom: 3.5rem;
	}

	@include media-breakpoint-down(lg) {
		&__map {
			order: 1;
			text-align: center;

			img,
			map {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&__descriptions {
			order: 2;
		}
	}

	@include media-breakpoint-down(md) {
		&__map {
			display: none;
		}

		&__heading {
			p {
				display: none;
			}
		}

		&__item {
			display: block;

			h3 {
				cursor: pointer;
				display: flex;
			    justify-content: space-between;
			    align-items: center;

				&:after {
					content: "+";
					font-size: 3rem;
					line-height: 1;
					margin-top: -6px;
	    			margin-bottom: -5px;
	    			display: inline-block;
				}

				&.open:after {
					content: "-";
					margin-top: -7px;
				    margin-right: 4px;
				}
			}

			p {
				display: none;
				margin-bottom: 2.5rem;

				&.open {
					display: block;
				}
			}
		}
	}
}

.pb-stats {
	padding: 4rem 0;

	&__stat {
		min-width: 20rem;
		padding: 1.5rem;

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 3rem 0;

		h2 {
			margin-bottom: 1rem;
		}

		&__stat {
			max-width: 100%;
			flex-basis: 100%;
			padding: 2.5rem 1.5rem;
		}
	}
}

.product-blocks {
	&__top {
		margin-bottom: 3rem;

		@include media-breakpoint-down(md) {
			.col-md-4,
			.col-md-8 {
				flex: 0 0 100%;
				max-width: 100%;
				margin-left: 0;
			}

			&.product-blocks__top--has-optional {
				.block-image {
					margin-bottom: 3rem;
				}
			}
		}
	}
	&__bottom {
		&--width-highjack {
			display: grid;
		    grid-template-columns: repeat(2, 1fr);
		    grid-auto-rows: 1fr;
		    grid-row-gap: 3rem;

			.col-lg-4, 
			.col-md-4 {
				flex: 0 0 100%;
    			max-width: 100%;
			}

			.block-link {
				.cover-image {
					height: 42rem;

					@include media-breakpoint-down(lg) {
		    			height: 32.6rem;
		    		}

		    		@include media-breakpoint-down(lg) {
		    			height: 100%;
		    		}
				}
			}

			@include media-breakpoint-down(md) {
				display: block;
				margin-bottom: 3rem;

				> div {
					flex: 0 0 100%;
    				max-width: 100%;
				}
			}
		}

		.block-image {
			&.col-md-4 {
				@include media-breakpoint-down(md) {
					max-width: 100%;
					flex-basis: 100%;
				}
			}
		}

		&--full-height {
			.block-text-image {
				flex: 0 0 100%;
    			max-width: 100%;
				.row {
					flex-direction: column;
				}
				.col-md-6 {
					flex: 0 0 100%;
    				max-width: 100%;
				}

				@include media-breakpoint-down(md) {
					.row {
						flex-direction: row;
					}
				}
			}

			.block-text-image__image,
			.block-text-image__text {
				height: 22.7rem;
				max-width: 100%;
				flex-basis: 100%;

				@include media-breakpoint-down(lg) {
					height: 30.15rem;
				}

				@include media-breakpoint-down(md) {
					height: auto;
				}
			}
		}

		@include media-breakpoint-down(md) {
			> div:not(:last-of-type) {
				margin-bottom: 3rem;
				overflow: hidden;
			}
		}
	}

	&__heading {
		padding-bottom: 3rem;
	}

	&__product {
		padding: 5rem 3rem 3rem;
		margin-bottom: 6rem;
	}

	&__stat {
		font-size: 2.1rem;
		font-weight: bold;
		color: $blue;

		h4 {
			margin: .25rem 0 0;
		}
	}

	&__content {
		@include media-breakpoint-down(md) {
			> div {
				flex: 0 0 100%;
	    		max-width: 100%;
			}
		}
	}

	.block-link {
		&.block-image {
			@include media-breakpoint-down(md) {
				height: 22.5rem;
			}

			.inner {
				padding: 0;
				height: 22.5rem;

				@include media-breakpoint-down(md) {
					height: 22.5rem;
				}
			}

			&.block-image--nocover {
				height: auto;
				max-height: 100%;

				.inner,img {
					height: auto;
					max-height: 100%;
				}
			}
		}

		.cover-image {
			width: 100%;
    		object-fit: cover;
    		height: 100%;

    		@include media-breakpoint-down(md) {
				height: 100%;
			}
		}
	}

	.block-text-image {
		.block-text-image__image {
			min-height: auto;

			@include media-breakpoint-down(sm) {
				.objectfit {
					height: 22.5rem;
				}
			}
		}
	}

	.block-blank {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.tabs {
	.nav-link {
		font-size: 1.7rem;
		color: $font-clr;
		font-weight: bold;
		&.show {
			color: $blue;
		}
	}

	.nav-item {
		display: inline-block;
		&:not(:last-of-type) {
			margin-right: 2rem;
		}
	}

	.nav-tabs {
		list-style: none;
		padding: 0;
		margin-bottom: 2rem;
	}

	.tab-pane {
		display: none;
		&.show {
			display: block;
		}
	}
}

.accordion {
	padding: 5rem 1.5rem;
	&__header {
		border-top: 1px solid #C9C9C9;

		button {
			appearance: none;
			background-color: transparent;
			border: none;
			cursor: pointer;
			padding: 1.4rem 1rem;
			width: 100%;
			text-align: left;
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&:after {
				content: "+";
				font-size: 3rem;
				line-height: 1;
				margin-top: -6px;
    			margin-bottom: -5px;
			}

			&[aria-expanded="true"]:after {
				content: "-";
				margin-top: -7px;
			    margin-right: 4px;
			}
		}

		&#heading-1 {
			border-bottom: none;
		}
	}

	.collapsing {
		position: relative;
	    height: 0;
	    overflow: hidden;
	    transition: height .35s ease;
	}

	&__body {
		&.collapse:not(.show) {
			display: none;
		}
	}

	&__inner {
		padding: 1rem;
	}

	a {
		font-weight: 700;
	}
}


.pb-line-list {
	$gap: 7rem;

	&.bg-primary {
		&:hover {
			background: var(--primary) !important;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	h3 {
		font-weight: 6006
	}

	&__ul {
		padding: -2rem 0 0 0;
	    margin-top: 3rem;
	    margin-bottom: 0;
	    padding-left: 0;
	    position: relative;

	    &:before {
			content: "";
			width: 1px;
			height: calc(100% - 4rem);
			background-color: rgba($primary, .25);
			position: absolute;
			top: 2rem;
			left: 50%;
		}
	}

	&.bg-blue,
	&.bg-primary {
		.pb-line-list__ul {
			&:before {
				background-color: rgba(white, .25);
			}
		}

		a {
			color: white;
		}
	}

	&__li {
		position: relative;
		list-style: none;
		float: left;
	    clear: left;
	    width: calc(50% - #{$gap});
	    margin: 0 $gap 4rem 0;
	    padding: 0 3rem 0 0;
	    text-align: right;

	    &:before {
			content: "";
			width: $gap;
			height: 1px;
			background-color: rgba($primary, .25);
			position: absolute;
			top: 2rem;
			right: -$gap;
		}

	    &.right,
		&.right-manual {
	    	float: right;
    		clear: right;
    		margin: 0 0 4rem $gap;
    		text-align: left;
    		padding: 0 0 0 3rem;

    		&:before {
				content: "";
				right: 0;
				left: -$gap;
			}

			&.first {
				margin-top: 2rem;
			}
	    }

	    &:last-of-type {
	    	margin-bottom: 1rem;
	    }
		
	}

	&.bg-blue,
	&.bg-primary {
		.pb-line-list__li {
			&:before {
				background-color: rgba(white, .25);
			}
		}
	}

	&__image {
		max-width: 25rem;
		max-height: 17rem;
		margin-bottom: 1.6rem;
	}

	&__icon {
		height: 4rem;
		margin-bottom: 1.6rem;
	}

	@include media-breakpoint-down(sm) {
		&__ul {
			display: block;
			border-left: 1px solid rgba($primary, .25);

			&:before {
				display: none;
			}
		}

		&__li {
			width: 100% !important;
			padding: 0 0 0 4rem !important;
			margin: 0 0 4rem !important;
			text-align: left !important;
			float: none;

			&:before {
				left: 0 !important;
				top: 0;
				width: 2rem;
			}

			&.right,
			&.right-manual {
				float: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&.bg-blue,
		&.bg-primary {
			.pb-line-list__ul {
				border-left: 1px solid rgba(white, .25);
			}
		}
	}

	&.override {
		@media(min-width: 768px) {
			.right-manual:not(.right) {
				float: left !important;
				clear: left !important;
				margin: 0 7rem 4rem 0 !important;
			    padding: 0 3rem 0 0 !important;
			    text-align: right !important;

			    &:before {
			    	right: -$gap !important;
			    	left: auto;
			    }
			}
		}
	}
}

.pb-form-area {
	&__heading {
		margin-bottom: 3rem;
	}
}

.pb-logo-slider {
	.slider-arrows {
		margin: 2rem 0 0 0;
	}

	&__header {
		margin-bottom: 4rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}
}

.logo--slider {
	&__group {
		display: flex !important;
		justify-content: center;
		flex-wrap: wrap;

		img {
			max-width: 100%;
		}
	}

	&__container {
		padding: 2rem;
		flex: 0 0 25%;
		max-width: 25%;

		@include media-breakpoint-down(md) {
			//flex-basis: 50%;
			//max-width: 50%;
		}
		@include media-breakpoint-down(sm) {
			flex-basis: 50%;
			max-width: 50%;
			display: flex;
			justify-content: center;
		}
	}
}

.pb-generic-text {
	padding: 0 0 3.3em;

	&--table {
		padding-top: 3.3em;

		.pb-blocks__header {
			margin-bottom: 0.9rem;
		}
	}
}

#ctf .ctf-tweet-meta {
	margin-left: 0;
	margin-right: 0;
}
#ctf.ctf-super-narrow .ctf-tweet-actions {
	font-size: 13px;
	margin-top: 15px;
}
#ctf .ctf-twitterlink {
	margin-top: 0;
	font-size: 13px;
}
#ctf #ctf-more {
	padding: 5px 0 6px;
	font-size: 13px;
}
#ctf .ctf-tweet-text a {
	word-break: break-all;
}

.bg-vitol-blue,
.bg-primary {
 	#ctf #ctf-more {
 		border: 1px solid white;
 	}
 	.ctf-tweet-actions a {
 		color: white !important;
 	}
 	.ctf-corner-logo svg path {
 		color: white;
 	}
}

.generic-text-block-padding {
	padding: 5rem 9rem;
}

.pb-location-map {
	.m-b-0 {
		margin-bottom: 0 !important;
	}

	.container {
		max-width: 1770px;
	}

	&__container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.location-map {
		padding: 50px;
		width: 500px;
		position: relative;

		&__content {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 10rem;
		}

		&__reveal {
			width: 24rem;

			&--inner {
				display: none;
				padding: 2rem;
    			background: #f7f7f7;
			}

			&--inner--open {
				display: block;
			}
		}

		&__map {
			height: auto;
			width: 100%;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}

		&__point {
			.location-map__marker--open {
				h3:after {
					width: 100%;
				}
			}
		}

		&__marker {
			cursor: pointer;

			img {
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.6rem;
				width: 2rem;
			}

			h3 {
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 0;
				position: relative;
				font-size: 1.6rem;

				&:after {
					content: "";
					display: block;
					width: 0;
					height: 2px;
					background: $blue;
					transition: width .3s ease-out;
				}
			}

			&:hover {
				h3:after {
					width: 100%;
				}
			}
		}
	}

	.mobile-only-text {
		display: none;
	}

	@media(max-width: 840px) {
		.m-b-0 {
			margin-bottom: 40px !important;
		}

		.location-map__map,
		.location-map__content {
			display: none !important;
		}

		.mobile-only-text {
			display: block;
		}

		.location-map__marker {
			margin-bottom: 50px;
			cursor: auto;

			h3 {
				font-size: 1.8rem;
				cursor: auto;

				&:after {
					display: none;
				}
			}

			.mobile-only-text {
				margin-top: 15px;
			}
		}

		.location-map {
			padding: 0;
			width: 100%;
		}

		.location-map__marker {
			position: relative !important;
			top: 0 !important;
			right: 0 !important;
		}
	}
}

.table-container {
	@media (max-width: 620px) {
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 2rem;
		
		table {
			min-width: 880px;
			width: 100%;
			table-layout: fixed;
		}
	}
}