//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

@media (min-width: 1440px) {
    #eu-cookie-bar, .container, .hero--slider .slick-dots {
        max-width: 1230px;
    }
}

.main {
    flex: 1 0 auto;
}

.content {
    position: relative;
}

// Search bar
// -----------------------------------------------------------------------------
.site-search {
    position: relative;
    margin-left: auto;

    .search-toggle {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: block;
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;
        margin-right: 3rem;
        position: relative;

        &:focus {
            outline: none;
        }
        
        svg {
            width: 100%;
            height: 100%;
        }

        &--open {
            .svg {
                display: none;
            }
            
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 50%;
                transform: rotate(45deg);
                height: 100%;
                width: 2px;
                background-color: #8A8D8F;
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    .search-form-container {
        display: block;
        position: absolute;
        z-index: 300;
        top: calc(100% + 4.9rem);
        right: 0;
        width: 39rem;
        background-color: white;
        padding: 2.2rem 2.8rem;
        box-shadow: 0 7px 8px -3px rgba(0,0,0,0.4);

        input[type="text"] {
            font-style: italic;
            font-size: 1.3rem;
            height: 4.2rem;
            border: none;
            border-radius: 0;
            padding: .5rem;
            border-bottom: 1px solid rgba(#63666A, .25);
            font-size: 17px;
            font-weight: 400;
            font-style: normal;
            background-color: transparent;
        }

        .submit-container {
            position: absolute;
            right: 2.8rem;
            top: 2.2rem;
            height: 4.2rem;
            width: 2rem;
            color: #8A8D8F;
            cursor: pointer;

            input {
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include media-breakpoint-down(md) {
            top: calc(100% + 2.2rem);
        }

        @include media-breakpoint-down(sm) {
            right: -5rem;
            width: 30rem;
        }

        @media(max-width: 520px) {
            right: 0;
            width: 100%;
            top: 7.5rem;
        }
    }

    @media(max-width: 520px) {
        position: inherit;
    }

    &--closed {
        .search-form-container {
            display: none;
        }
    }
}

.search-no-results {
    .post-list {
        padding-top: 5rem;
        
        input {
            background-color: #f7f7f7 !important;
            margin-top: 2rem;
            padding: 14px;
        }
    }
}

.image-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.image-overlay {
    position: relative;
    
    &:before {
        content: "";
        background-color: rgba(24, 41, 64, 0.65);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 5;
        
        @supports(mix-blend-mode: multiply) {
            background-color: rgba(99, 102, 106, 0.65);
            mix-blend-mode: multiply;
        }
    }

    &--nooverlay {
        &:before {
            display: none;
        }
    }
}

/* Table styles */
table {
    width: 100%;
    border-spacing: 0;
    font-size: 1.5rem;

    thead {

        th {
            padding: 0 0 1rem;
            border-bottom: 1px solid #d0d0d0;
            color: $blue;

            > * {
                margin-bottom: 0;
            }
        }
    }

    th, td {
        vertical-align: top;
    }

    tr > th + th, 
    tr > td + td {
        padding-left: 1rem;
    }

    tbody {
        border-bottom: 1px solid #d0d0d0;

        tr:first-of-type {
            td {
                padding-top: 1rem;
            }
        }

        tr:last-of-type {
            td {
                padding-bottom: 1rem;
            }
        }

        td {
            padding: 0.5rem 0;
            color: $blue;

            > h3 {
                margin-bottom: 0;
            }
        }    
    }
}


/* Utility classes */
.p-b-0 {
    padding-bottom: 0 !important;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

@include media-breakpoint-down(sm) {
    [data-hidden="hide"] {
        display: none !important;
    }
}

img.full-width {
    width: 100%;
}


/* Custom column */
.row {
    .col-fifth {
        flex-basis: 20%;
        max-width: 20%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 30px;
        }
    }
}