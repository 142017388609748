//******************************************************************************
// PAGES
// Common styles shared by multiple pages
// Unless you're working on very large site or application, most of the
// page-specific code should go in here.
//******************************************************************************

.page-template-template-landingpage {
	#primary-navigation,
	.site-search,
	.navbar-toggler {
		display: none;
	}

	.pb--reduce-padding {
		&--first {
			padding-top: 10rem !important;
		}
		&--last {
			padding-bottom: 10rem !important;
		}
	}
}

.error404 {
	padding-top: 0;
	.site-header {
		position: relative;
		
		h1 {
			margin-bottom: 3rem;
		}

	}
}

.error-404-page {
	position: relative;
	padding: 22rem 0;
	min-height: calc(100vh - 28rem);
	
	a {
		color: white;
		font-weight: bold;
		display: inline-block;
		margin-top: 2rem;
		font-size: 1.7rem;

		&:hover {
			text-decoration: underline;
		}
	}

	.image-overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: block;
	}

	.image-cover {
		position: absolute;
		top: 0;
	}

	.container {
		position: relative;
		z-index: 10;
		color: white;
	}
}