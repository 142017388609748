//******************************************************************************
// HEADER
//******************************************************************************
.site-header {
	width: 100%;
	background-color: #fff;
	position: fixed;
	z-index: 500;
	top: 0;
	padding-top: 4.2rem;
	padding-bottom: 4.2rem;
	transition: all .3s ease-in-out;
	box-shadow: 0px 4px 5px -6px rgba(0, 0, 0, 0.7);
	
	.navbar {
		.navbar-brand {
			padding: 0;
			margin: 0 2rem 0 0;
			.logo {
				height: 4.2rem;

				@include media-breakpoint-down(md) {
					height: 3.2rem;
				}
				@media (max-width: 1366px) and (max-height: 768px) {
					height: 3.2rem;
				}
			}
		}
	}

	.top-navbar {
		width: 100%;
		padding-right: 10px;

		@include media-breakpoint-down(md) {
			margin-bottom: 10px;
		}

		.social-list {
			li {
				margin: 0 1rem;
				&:after {
					display: none;
				}

				img {
					width: 15px;
				}
			}
		}
	}

	&--hidden {
		top: -13rem;
		transition: all .3s ease-in-out;

		@include media-breakpoint-down(md) {
			@supports(scroll-behavior: smooth) {
				top: 0 !important;
			}
		}

		&.open {
			top: 0 !important;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 2.2rem;
    	padding-bottom: 2.2rem;
	}
	@media (max-width: 1366px) and (max-height: 768px) {
		padding-top: 2.2rem;
    	padding-bottom: 2.2rem;
	}
}

.admin-bar {
	.site-header {
		margin-top: 32px;
	}
}