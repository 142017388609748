.body--microsite {

	.site-header {
		padding-top: 3.4rem;
		padding-bottom: 3.4rem;
		
		.navbar .navbar-brand .logo {
		    height: 5.7rem;
		}

		@include media-breakpoint-down(md) {
		    padding-top: 2.2rem;
    		padding-bottom: 2.2rem;

    		.navbar .navbar-brand .logo {
			    height: 3.2rem;
			}
		}
	}

}