//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************

$menu-col-width: 450px;
$menu-col-width-lg: 356px;
$menu-height: 500px;

:root {
    --menu-min-height: 500px;
}

.main-nav {
    .navbar {
        width: 100%;
        padding: 0 15px;
        position: inherit;

        @include media-breakpoint-down(md) {
        	justify-content: flex-start;
        }
    }
}

#primary-navigation {
	margin-left: auto;

    @include media-breakpoint-down(md) {
		display: none;
    	position: absolute;
	    top: 100%;
	    width: 100%;
	    left: 0;
		box-shadow: 0px 4px 5px -6px rgba(0, 0, 0, 0.7);
    }

    .navbar-nav {
    	display: block;

		@include media-breakpoint-down(md) {
            width: 100%;
	        position: relative;
	        background-color: white;
	        padding: 2rem 3rem;
        }
    }
	
    ul#menu-main-menu {
        @include media-breakpoint-down(md) {
            padding-bottom: 4rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .dropdown {
        .dropdown-menu {
            position: absolute;
            padding: 0 20px;
            min-width: 160px;
        }
    }

    .nav-link {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.2;
        display: block;
        padding: 1.2rem 0;
        color: $font-clr;
        text-decoration: none;

        &:hover {
            color: #9ea0a2;
        }
    }

    .menu-item--level-0 {
    	display: inline-block;
    	margin-right: 2.5rem;

        > .nav-link {
            font-size: 1.7rem;
            position: relative;
        }

        @include media-breakpoint-up(lg) {
            &.current_page_item,
            &.current_page_parent {
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-bottom-color: grey;
            }
        }

        @include media-breakpoint-down(md) {
			display: block;
			margin-right: 0;

			.nav-link {
				border-bottom: 1px solid rgba(#8A8D8F, .15);
			}
        }
    }

    &.show {
    	display: block;
    }
}

.navbar-toggler {
    padding: 0;
    border: 0;
    border-radius: 0;
    display: block;
    padding: 10px 2px;
    display: none;

    @include media-breakpoint-down(md) {
    	display: block;
    	margin-left: 2.5rem;
    }

    .navbar-toggler-icon {
        position: relative;
        display: inline-block;
        width: 2.6rem;
        height: 2px;
        background: #8A8D8F;
        transition: all .2s ease-in-out;

        &:before,
        &:after {
            background: #8A8D8F;
            content: '';
            display: block;
            width: 2.6rem;
            height: 2px;
            position: absolute;
            transition: all .2s ease-in-out;
        }
        &:before {
            top: -0.9rem;
        }
        &:after {
            top: 0.9rem;
        }
    }

    &:focus {
        outline: none;
    }

    &.open{
        .navbar-toggler-icon {
            background: transparent;
            &:before,
            &:after {
                top: 0;
                height: 2px;
            }
            &:before {    
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:after {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }  
    }
}

.product-blocks__quick-links {
    margin: 6.9rem 1.5rem 8rem;

    &__btn {
        font-size: 1.7rem;
        font-weight: bold;
    }

    &--sticky {
        position: sticky;
        top: 0;
        z-index: 100;
        box-shadow: 0 4px 5px -6px rgba(0,0,0,.7);
        padding: 2rem 1.5rem;
    }

    @include media-breakpoint-down(md) {

        margin: 4rem 1.5rem;

         &--sticky {
            position: relative;
            z-index: 100;
            box-shadow: none;
            padding: 0;
        }

        &__btn {
            margin: 2rem 0 0 1.5rem;
        }
    }
}

.product-blocks__quick-links-dropdown {
    width: 100%;
    max-width: 33rem;
    position: relative;

    button {
        appearance: none;
        background-color: white;
        border: none;
        //box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid #dedede;
        height: 5rem;
        text-align: left;
        font-size: 1.7rem;
        color: $font-clr;
        padding: 0 3rem 0 1rem;
        position: relative;
        cursor: pointer;
        width: 100%;

        &:hover {
            background-color: rgba(222, 222, 222, 0.84);
        }
        
        &:after {
            content: "";
            position: absolute;
            background: url('../assets/images/arrow.svg') no-repeat center;
            background-size: contain;
            width: 8px;
            height: 15px;
            right: 12px;
            top: 18px;
            transform: rotate(90deg);
        }
    }

    ul {
        display: none;
        font-size: 1.5rem;
        padding: 2rem 3rem 2rem 2rem;
        width: 100%;
        background-color: white;
        list-style: none;
        position: absolute;
        z-index: 10;
        top: 100%;
        box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);

        li {
            
        }

        a {
            color: $font-clr;
            padding: .5rem 0;
            display: block;
        }
    }

    &--open {
        button {
            &:after {
                transform: rotate(-90deg);
            }
        }
        ul {
            display: block;
        }
    }
}

html[data-useragent*="rv:11.0"] {
    #primary-navigation .dropdown {
        position: static;
    }
}