//******************************************************************************
// FOOTER
//******************************************************************************
.site-footer {
    &__top {
        position: relative;
        padding-top: 2.7rem;
        padding-bottom: 2.7rem;

        &:after {
            content: "";
            background: rgba($font-clr, .25);
            width: calc(100% - 3rem);
            height: 1px;
            display: block;
            position: absolute;
            left: 1.5rem;
            bottom: 0;
        }
    }

    &__bottom {
        padding-top: 2.7rem;
        padding-bottom: 2.7rem;

        * {
            font-size: 13px!important;
            color: $font-clr;
            margin-bottom: 0;
        }

        a:hover {
            color: $blue;
        }
    }

    .footer-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .menu {
        padding: 0;
        list-style: none;
        margin: 0 0 0 1rem;

        li {
            display: inline-block;
            margin: 0 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .footer-menu {
            display: block;

            ul {
                margin-left: 0;
            }

            li {
                display: block;
                text-align: center;

                &:not(:last-of-type) {
                    margin-bottom: 2rem;
                }
            }

            p {
                margin-bottom: 2rem;
            }
        }
    }
}

.social-list {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin: 0 1.5rem;
        position: relative;
        opacity: 1;
        transition: opacity .1s ease;

        &:after {
            content: "\00b7";
            font-size: 2rem;
            position: absolute;
            top: -35%;
            left: 151%;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        &:hover {
            opacity: .6;
        }
    }
}

#eu-cookie-bar {
    @extend .container;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.6rem;

    .inner {
        background-color: $blue;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
    }

    #euCookieAcceptWP {
        color: white !important;
        background-color: transparent !important;
        position: relative;
        font-size: 0;
        top: -4px;
        margin-left: 3rem;
        margin-bottom: -6px;

        &:after {
            content: "+";
            font-size: 4rem;
            font-weight: 100;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;

        .inner {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}