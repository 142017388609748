//******************************************************************************
// SLIDER
// Carousel styles
//******************************************************************************

.slider {
	position: relative;
	
	&__bg {
		z-index: 1;
		position: absolute;
	}

	.container {
		position: relative;
		z-index: 10;
	}
}

.slick-dots {
	padding: 0;
	list-style: none;

	li {
		padding: 0;
		margin: 0;
		display: inline-block;
		margin-right: .8rem;
		border-radius: 50%;
	}

	button {
		height: 10px;
		width: 10px;
		background-color: #B6BABC;
		display: block;
		border-radius: 50%;
		border: none;
		font-size: 0;
		appearance: none;
		cursor: pointer;
		padding: 0;
	}

	.slick-active {
		button {
			background-color: white;
		}
	}
}

.slider-arrows {
	margin: 4.4rem 0 2rem -0.5rem;
	
	.slider-arrow {
		appearance: none;
    	text-align: center;
		padding: .5rem;
	    height: 2.4rem;
	    width: 2.3rem;
		border: none;
		background-color: transparent;
		cursor: pointer;

		&#left-arrow {
			img {
				transform: rotate(180deg);
			}
		}

		&:hover {
			filter: contrast(0);
		}
	}
}