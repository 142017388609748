//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

$formInputClr: #F2F2F2;

// Base Inputs
// -----------------------------------------------------------------------------
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    width: 100%;
    min-width: 100px;
    font-size: 17px;
    border: 0;
    padding: 20px 14px 6px;
    background-color: $formInputClr;
    border-bottom: 2px solid #dedede;
    color: $blue;

    &:hover {
        background-color: #e0dfdf;
    }
}
select {
    height: 51px;
    padding: 10px 12px;
    color: $font-clr;
}

input[type="radio"],
input[type="checkbox"] {
    //-webkit-appearance: none;
    //-moz-appearance:    none;
    //appearance:         none;
}

.frm_screen_reader {
    display: none;
}
.frm_hidden_container,
.frm_none_container {
    .frm_primary_label {
        display: none;
    }
}

.bg-light {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
        background-color: white;

        &:hover {
            background-color: #e0dfdf;
        }
    }
}

// Placeholder
// -----------------------------------------------------------------------------
::-webkit-input-placeholder {
    color: #8A8D8F;
    font-style: italic;
}
::-moz-placeholder {
    color: #8A8D8F;
    font-style: italic;
}
:-ms-input-placeholder {
    color: #8A8D8F;
    font-style: italic;
}
:-moz-placeholder {
    color: #8A8D8F;
    font-style: italic;
}

.form-area {
    .wpml-ls-legacy-dropdown{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: end;
        padding-bottom: 1rem;
    }
}

.frm_forms {
    max-width: 69rem;
    margin: 0 auto;

    .frm_primary_label {
        position: absolute;
        top: 1.3rem;
        left: 1.4rem;
        font-style: italic;
        font-size: 1.7rem;
        margin-bottom: 0;
        transition: all .2s linear;
        color: #8A8D8F;
    }

    .form-field {
        position: relative;
        margin-bottom: 1.6rem;

        &.active {
            .frm_primary_label {
                top: 3px;
                left: 1.3rem;
                font-size: 11px;
            }
        }

        &.frm_two_col {
            .frm_checkbox {
                display: inline-block;
                width: 49%;

                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 100%;
                }
            }
        }

        &.job-interests {
            .frm_opt_container {
                background: rgba(#F2F2F2, .5);
                padding: 14px 28px;
            }

            .frm_checkbox {
                padding: .4rem 0;
            }

            .frm_description {
                min-height: 51px;
                font-size: 17px;
                text-align: left;
                background-color: $formInputClr;
                border: none;
                padding: 13px 14px;
                border-bottom: 2px solid #dedede;
                cursor: pointer;
                color: $font-clr;
            }
        }

        &.frm_blank_field {
            input,
            select,
            .frm_dropzone:not(.dz-max-files-reached) .frm_upload_text button {
                border: 1px solid #D50032;
            }

            .frm_error {
                background-color: #D50032;
                padding: 4px;
                color: white;
                font-size: 15px;
                position: relative;
                
                &:before {
                    content: "";
                    background: url('../assets/images/alert-triangle.png') no-repeat center;
                    background-size: contain;
                    height: 13px;
                    width: 15px;
                    display: inline-block;
                    margin: 0 5px;
                }
            }

            .frm_dropzone.dz-max-files-reached + .frm_error,
            .frm-g-recaptcha + .frm_error {
                display: none;
            }

            textarea {
                display: block;
                border: 1px solid #d51131;
                border-bottom: none;
            }
        }

        &.vertical_radio {
            input[type="radio"] {
                display: none;
                font-size: 2.7rem;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            [aria-labelledby="field_formselector_label"] {
                text-align: center;
            }

            .frm_radio {
                display: inline-block;
                padding: 0 1.5rem;

                label {
                    font-size: 2.7rem;
                    cursor: pointer;
                    position: relative;
                    color: #8A8D8F;
                }

                &.active {
                    label {
                        color: $blue;
                    }
                }
            }
        }

        &.frm_half {
            display: inline-block;
            width: 48%;

            &:nth-of-type(even) {
                //margin-left: 3.7%;
                margin-left: 3.6%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                display: block;

                &:nth-of-type(even) {
                    margin-left: 0;
                }
            }
        }
    }

    .frm_checkbox {
        padding: 1.4rem 0;
        label {
            left: 0;
            top: 0;
            padding-left: 3.6rem;
            font-size: 1.5rem;
            color: #8A8D8F;
            position: relative;
            cursor: pointer;

            input {
                display: none;
            }

            a {
                color: $font-clr;
                text-decoration: underline;

                &:hover {
                    color: $blue;
                }
            }
        }
        label:before {
            content:"";
            cursor: pointer;
            position: absolute;
            width: 19px;
            height: 19px;
            top: 2px;
            left: 0px;
            background: white;
            border:1px solid #707070;
        }
        label:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 12px;
            height: 7px;
            background: 0 0;
            top: 7px;
            left: 4px;
            border: 2.5px solid $blue;
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
        }
        label.active {
            &:after {
                opacity: 1 !important;
            }
        }
    }

    .frm_dropzone {
        .dz-message {
            background-color: $formInputClr;
            height: 51px;

            .frm_small_text {
                display: none;
            }

            .dz-image,
            .dz-column {
                display: none;
            }

            .dz-success,
            .dz-complete {
                .dz-column {
                    display: block;
                }
            }

            .frm_compact_text {
                position: absolute;
                right: 12px;
                top: 12px;

                button {
                    appearance: none;
                    background: transparent;
                    border: none;
                    font-size: 15px;
                    color: $font-clr;
                }
            }

            &:hover {
                background-color: #e0dfdf;
            }
        }

        .frm_upload_text {
            button {
                position: absolute;
                top: 0;
                width: 100%;
                height: 51px;
                font-size: 17px;
                text-align: left;
                background-color: $formInputClr;
                border: none;
                padding-left: 14px;
                border-bottom: 2px solid #dedede;
                cursor: pointer;
                color: $font-clr;

                &:after {
                    content: "*";
                }

                &:hover {
                    background-color: #e0dfdf;
                }
            }
        }

        .dz-details {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            font-size: 1.3rem;
        }

        .dz-filename {
            margin-left: 1rem;
        }

        .dz-remove {
            height: 3rem;
            width: 3rem;
            background: $formInputClr;
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 1rem;
            margin-top: -0.6rem;

            &:after {
                content: "+";                
                transform: rotate(45deg);
                font-size: 3rem;
                position: absolute;
                top: -31%;
                left: 24%;
                color: $font-clr;
            }

            &:hover {
                background: #c1c1c1;
            }
        }
    }

    .frm_message {
        background-color: #f2f2f2!important;
        padding: 10rem;
        text-align: center;

        p {
            margin-bottom: 0;
        }

        > * + * {
            margin-top: 2rem;
        }

        @include media-breakpoint-down(sm) {
            padding: 4rem 2rem;
            margin-top: 11rem;
        }
    }
}

.frm_submit {
    float: right;
    margin-top: -7rem;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        float: none;
        margin-top: 4rem;
    }
}

.frm_button_submit {
    @extend .btn;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
        color: $font-clr;
    }
}

.frm_verify {
    display: none;
}