$font-family: 'Lato', sans-serif;

$font-clr: #63666A;
$blue: #002B54;
$primary: #002B54;

:root {
	--blue: #002B54 !important;
	--primary: #002B54 !important;
}

// BOOTSTRAP GRID
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1124px,
  xl: 1440px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1100px,
  xl: 1380px
);