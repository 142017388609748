//******************************************************************************
// TYPOGRAPHY
//******************************************************************************


// Headings
// -----------------------------------------------------------------------------
// Define a reusable & flexible class for each heading, which can be used
// everywhere in the page to replicate the style of the headings while keeping
// a clean structure.
// http://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/



h1, .alpha {
	@include fluid-type(2.6rem, 2.6rem);
	font-weight: 400;
	color: inherit;
	line-height: 1.1;
}

h2, .beta {
	font-size: 1.8rem;
	font-weight: 400;
	color: $blue;
	line-height: 1.3;
	margin-bottom: 1.5rem;
}

h3, .gamma {
	font-size: 1.8rem;
	font-weight: 400;
	color: $blue;
	margin-bottom: .5em;
	line-height: 1.1;
}

h4, .delta {
	color: $blue;
	font-size: 1.9rem;
	line-height: 1.1;
	margin-bottom: 0;

	a {
		color: inherit;
	}
}

h5, .epsilon {

}

h6, .zeta {

}


.pre-header,
.pre-heading {
	font-size: 1.1rem;
	text-transform: uppercase;
	margin-bottom: 5rem;
	line-height: 1;
}

.pb-generic-text,
.pb-accordion,
.post-area {
	ul, 
	ol {
		padding-left: 2rem;

		li {
			font-size: 1.5rem;
		}
	}
}